import setupClickHandlerForConsentLink from './setupClickHandlerForConsentLink';
import maybeOpenPrivacyManagerModal from './maybeOpenPrivacyManagerModal';
import isBot from './isBot';
import isMobileApp from './isMobileApp';
import saveCookie from './saveCookie';
import getConsentState from './getConsentState';
import waitForTCData from './waitForTCData';
import {
  EVENT_LABEL,
  logConsentBannerLoaded,
  logConsentGranted,
  logConsentDenied,
  logSourcepointLibError,
} from './analytics';
import { CHOICE_TYPE, PURPOSE_CONSENT, VENDOR_CONSENT } from './analytics';
import { w } from './global';

const loadSourcepoint = !(isBot() || isMobileApp());
let isSettingsPageOpen = false;

const { LAYER_ONE, LAYER_TWO, SCOPE_ALL, SCOPE_PARTIAL } = EVENT_LABEL;

w._sp_lib = {
  getConsentState,
  waitForTCData,
};
w._sp_queue = [];
w._sp_ = {
  config: {
    accountId: w._sp_ss_config.accountId,
    baseEndpoint: w._sp_ss_config.baseEndpoint,
    propertyHref: encodeURI(w.location.href),
    gdpr: {
      groupPmId: w._sp_ss_config.privacyManagerModalId,
    },
    // if true sourcepoint autoload is disabled
    isSPA: !loadSourcepoint,
    events: {
      onConsentReady: () => {
        saveCookie();
      },
      onMessageReady: function () {
        if (isSettingsPageOpen) {
          //  1. amount of users / sessions exposed to Sourcepoint consent banner (banner loading is triggered)
          logConsentBannerLoaded([LAYER_TWO]);
        } else {
          // 3. amount of users who opened the 2nd layer. This means that they either clicked on the Settings button on the 1st layer, or opened the privacy manager.
          logConsentBannerLoaded([LAYER_ONE]);
        }
      },
      onMessageChoiceSelect: function (messageType, choiceId, choiceTypeId) {
        if (choiceTypeId === CHOICE_TYPE.ACCEPT_ALL) {
          //  2. amount of users who chose “accept all” on the 1st layer
          logConsentGranted([LAYER_ONE, SCOPE_ALL]);
        } else if (choiceTypeId === CHOICE_TYPE.REJECT_ALL) {
          logConsentDenied([LAYER_TWO]);
        } else if (choiceTypeId === CHOICE_TYPE.SETTINGS) {
          isSettingsPageOpen = true;
        }
      },
      onPrivacyManagerAction: function (messageType, { vendorConsent, purposeConsent }) {
        if (purposeConsent === PURPOSE_CONSENT.ALL && vendorConsent === VENDOR_CONSENT.ALL) {
          // 6. amount of users who chose the “save and continue” option on the 2nd layer, while they accepted all the 10 purposes and all the vendors by enabling all the toggles.
          // 7. amount of users who chose “accept all” on the 2st layer
          logConsentGranted([LAYER_TWO, SCOPE_ALL]);
        } else if (purposeConsent === PURPOSE_CONSENT.NONE && vendorConsent === VENDOR_CONSENT.NONE) {
          // 4. amount of users who chose the “save and continue” option on the 2nd layer, while they didn’t provide any consent. That means they either provided only legitimate interest (they didn’t change the settings) or rejected everything including legitimate interest (by disabling all the toggles).
          logConsentDenied([LAYER_TWO]);
        } else if (purposeConsent === PURPOSE_CONSENT.SOME && vendorConsent === VENDOR_CONSENT.SOME) {
          // 5. amount of users who chose the “save and continue” option on the 2nd layer , while they provided partial consent. This means that they consented to some of the purposes or vendors, but not to all of them.
          logConsentGranted([LAYER_TWO, SCOPE_PARTIAL]);
        }
      },
      onError: function (messageType, errorCode, errorObject) {
        logSourcepointLibError([JSON.stringify(errorObject)]);
      },
    },
  },
};

if (loadSourcepoint) {
  w.addEventListener('DOMContentLoaded', () => {
    setupClickHandlerForConsentLink();
  });
  w._sp_queue.push(() => {
    maybeOpenPrivacyManagerModal();
  });
}
